import React, { useMemo, useState } from 'react';
import ModalContext from 'context/ModalContext';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Modal from 'components/Modal';
import Zalo from '@zalo';
import FBMessenger from '@facebook';

export default function Layout( { children } ) {
  const [ modal, setModal ] = useState( null );

  const modalPool = useMemo( () => ( { openModal: setModal } ), [] );

  return (
    <ModalContext.Provider value={ modalPool }>
      <Navbar />
      <main className="min-h-screen">
        {children}
        <Modal open={ modal != null } onClose={ () => setModal( null ) } >
          {modal}
        </Modal>
      </main>
      <Footer />
      <Zalo phoneNumber="0792268936" />
      <FBMessenger />
    </ModalContext.Provider>
  );
}
