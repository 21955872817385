import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import '../styles/globals.css';
import Layout from 'components/Layout';
import * as ga from '@googleAnalytics';

function MyApp( { Component, pageProps } ) {
  const router = useRouter();

  useEffect( () => {
    const handleRouteChange = ( url ) => {
      ga.pageView( url );
    };
    router.events.on( 'routeChangeComplete', handleRouteChange );
    return () => {
      router.events.off( 'routeChangeComplete' );
    };
  }, [ router.events ] );

  return (
    <>
      <Script
        async
        src={ `https://www.googletagmanager.com/gtag/js?id=${ process.env.GOOGLE_ANALYTICS_ID }` }
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', '${ process.env.GOOGLE_ANALYTICS_ID }');
          `}
      </Script>
      <Layout>
        <Component { ...pageProps } />
      </Layout>
    </>
  );
}

export default MyApp;
