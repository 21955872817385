import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

const generateLink = ( phoneNumber ) => phoneNumber && `https://zalo.me/${ phoneNumber }`;

function ZaloIcon( { alt, link } ) {
  const Wrapper = link ? 'a' : 'div';
  const wrapperProps = link ? { target: '_blank', rel: 'noopener noreferrer', href: link } : {};
  return (
    <Wrapper className="unset-img" { ...wrapperProps }>
      <Image
        placeholder="blur"
        blurDataURL="/images/placeholder.png"
        src="/zalo.svg"
        alt={ alt }
        className="custom-img"
        layout="fill"
        unoptimized
      />
    </Wrapper>
  );
}

export default function Zalo( { phoneNumber } ) {
  return (
    <div className="fixed w-14 h-14 bottom-24 right-6">
      <ZaloIcon alt={ phoneNumber } link={ generateLink( phoneNumber ) } />
    </div>
  );
}

Zalo.propTypes = {
  phoneNumber: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ).isRequired,
};
