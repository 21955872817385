import React from 'react';
import PropTypes from 'prop-types';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { APP_ID, PAGE_ID } from './config';

export default function FBMessenger( { color } ) {
  return <MessengerCustomerChat appId={ APP_ID } pageId={ PAGE_ID } themeColor={ color } />;
}

export function FanPagePlugin() {
  const params = {
    href: `https://www.facebook.com/${ PAGE_ID }`,
    appId: APP_ID,
    width: 340,
    height: 130,
    small_header: false,
    adapt_container_width: true,
    hide_cover: false,
    show_facepile: true,
  };
  const url = new URL( 'https://www.facebook.com/plugins/page.php' );
  url.search = new URLSearchParams( params );
  return (
    <iframe
      title="fanpage_facebook"
      src={ url }
      width="340"
      height="130"
      style={ { border: 'none', overflow: 'hidden' } }
      scrolling="no"
      frameBorder="0"
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />
  );
}

FBMessenger.propTypes = {
  color: PropTypes.string,
};
