import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { BsYoutube, BsFacebook } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import { FanPagePlugin } from '@facebook';

export default function Footer() {
  const copyright = 'Designed & Built by An Le';
  return (
    <>
      <div className="max-w-7xl mx-auto py-4 mt-8 flex flex-col gap-4 md:block">
        <div className="inline-block self-center">
          <FanPagePlugin />
        </div>
        <div className="px-4 inline-grid grid-cols-2 gap-4 md:p-0 md:ml-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 align-top">
          <Link href="https://www.youtube.com/channel/UCl4T9fsSMrs7C1Uvf5tXGhg" passHref>
            <a className="flex gap-2 items-start" target="_blank" rel="noreferrer">
              <BsYoutube className="w-6 h-6 text-red-600" />
              <span>Nghiện Fifa</span>
            </a>
          </Link>
          <Link href="https://zalo.me/0792268936" passHref>
            <a className="flex gap-2 items-start" target="_blank" rel="noreferrer">
              <Image
                placeholder="blur"
                blurDataURL="/images/placeholder.png"
                src="/zalo.svg"
                alt="zalo 0792268936"
                width={ 24 }
                height={ 24 }
                unoptimized
              />
              <span>0792268936</span>
            </a>
          </Link>
          <Link href="https://www.facebook.com/dinhan.davidbi" passHref>
            <a className="flex gap-2 items-start" target="_blank" rel="noreferrer">
              <BsFacebook className="w-6 h-6 text-blue-600" />
              <span>Phước Chelsea</span>
            </a>
          </Link>
          <Link href="https://www.tiktok.com/@nghienfifaofficial" passHref>
            <a className="flex gap-2 items-start" target="_blank" rel="noreferrer">
              <FaTiktok className="w-6 h-6" />
              <span>@nghienfifaofficial</span>
            </a>
          </Link>
          <Link href="https://shopee.vn/nghien_quan_ao" passHref>
            <a className="flex gap-2 items-start" target="_blank" rel="noreferrer">
              <Image
                placeholder="blur"
                blurDataURL="/images/placeholder.png"
                src="/images/shopee.png"
                alt="zalo 0792268936"
                width={ 74 }
                height={ 24 }
                unoptimized
              />
              <span>Phước Store</span>
            </a>
          </Link>
          <Link href="https://shopee.vn/davidmoi" passHref>
            <a className="flex gap-2 items-start" target="_blank" rel="noreferrer">
              <Image
                placeholder="blur"
                blurDataURL="/images/placeholder.png"
                src="/images/shopee.png"
                alt="zalo 0792268936"
                width={ 74 }
                height={ 24 }
                unoptimized
              />
              <span>The Moji Shop</span>
            </a>
          </Link>
        </div>
      </div>
      <footer className="w-full py-2 font-extralight text-sm text-center">
        <strong>&copy;&nbsp;</strong>
        <span>{new Date().getFullYear()}</span>
        <span>&nbsp;</span>
        <span>{copyright}</span>
        <span>&nbsp;&#128516;</span>
      </footer>
    </>
  );
}
