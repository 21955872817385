import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import fo4LogoImg from 'public/images/fo4-logo.png';

const navigation = [
  { name: 'Trang Chủ', href: '/' },
  { name: 'Bài Đăng', href: '/post' },
  { name: 'Liên Hệ', href: '/contact' },
];

export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-teal-900">
      {( { open } ) => (
        <>
          <div className="max-w-7xl mx-auto">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {
                    open
                      ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  }
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Image
                    src={ fo4LogoImg }
                    width={ 244.8 }
                    height={ 40 }
                    alt="fo4-logo"
                    placeholder="blur"
                    priority
                    unoptimized
                  />
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    { navigation.map( ( item ) => (
                      <Link key={ item.name } href={ item.href }>
                        <a
                          className={ classNames( 'px-3 py-2 rounded-md text-sm font-medium hover:bg-teal-700 text-white' ) }
                          href={ item.href }
                        >
                          {item.name}
                        </a>
                      </Link>
                    ) ) }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              { navigation.map( ( item ) => (
                <Disclosure.Button
                  key={ item.name }
                  as="a"
                  href={ item.href }
                  className={ classNames( 'block px-3 py-2 rounded-md text-base font-medium hover:bg-teal-700 text-white' ) }
                >
                  {item.name}
                </Disclosure.Button>
              ) ) }
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
